/* this file is transformed by vux-loader */
/* eslint-disable */
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import quillConfig from '../../../assets/quill-config';
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      quillOption: quillConfig,
      contents: ""
    };
  },
  methods: {
    onConfirm() {
      this.$emit("wbHtml", this.contents);
    },
    input(val) {
      this.$emit('input', val);
    },
    onEditorBlur(e) {
      // 失去焦点事件
      this.$emit('blur', e);
    },
    onEditorFocus(e) {
      // 获取焦点事件
      this.$emit('focus', e);
    },
    onEditorChange(e) {
      // change事件
      this.$emit('change', e);
    }
  }
};