/* this file is transformed by vux-loader */
/* eslint-disable */
import axios from "axios"; // 这里使用axios封装了http请求
const uploadConfig = {
  name: 'img',
  // 必填参数 文件的参数名
  accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon' // 可选 可上传的图片格式
};

const toolOptions = [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
  'header': 1
}, {
  'header': 2
}], [{
  'list': 'ordered'
}, {
  'list': 'bullet'
}], [{
  'script': 'sub'
}, {
  'script': 'super'
}], [{
  'indent': '-1'
}, {
  'indent': '+1'
}], [{
  'direction': 'rtl'
}], [{
  'size': ['small', false, 'large', 'huge']
}], [{
  'header': [1, 2, 3, 4, 5, 6, false]
}], [{
  'color': []
}, {
  'background': []
}], [{
  'font': []
}], [{
  'align': []
}], ['clean'], ['image', 'video']];
const handlers = {
  image: function image() {
    var self = this;
    var fileInput = this.container.querySelector('input.ql-image[type=file]');
    if (fileInput === null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      if (uploadConfig.name) {
        fileInput.setAttribute('name', uploadConfig.name);
      }
      fileInput.setAttribute('accept', uploadConfig.accept);
      fileInput.classList.add('ql-image');
      fileInput.addEventListener('change', async () => {
        console.log(fileInput.files[0]);
        const formData = new FormData();
        formData.append("id", "WU_FILE_0");
        formData.append("name", fileInput.files[0].name);
        formData.append("type", fileInput.files[0].type);
        formData.append("lastModifiedDate", fileInput.files[0].lastModifiedDate);
        formData.append("size", fileInput.files[0].size);
        formData.append('upfile', fileInput.files[0]);
        let url = _baseUrl + "/ueditor/upload.do?action=uploadfile&encode=utf-8";
        axios.post(url, formData).then(res => {
          if (res.data.url != "") {
            let length = self.quill.getSelection(true).index;
            //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。            
            self.quill.insertEmbed(length, 'image', res.data.url);
            self.quill.setSelection(length + 1);
          }
          fileInput.value = '';
        });
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  }
};
export default {
  placeholder: '',
  theme: 'snow',
  // 主题
  modules: {
    toolbar: {
      container: toolOptions,
      // 工具栏选项
      handlers: handlers // 事件重写
    }
  }
};